import React, { useState } from "react"
import { Link } from "gatsby"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { Modal } from "react-bootstrap"
import TalentPoolModal from "./talent-pool-modal"
import { isSafariLess16 } from "../utils.js"
import * as style from "./hero.module.css"

const OpenRoleHero = ({ pagedata, globals }) => {
  const vacancy = pagedata

  const [showTalentPool, setShowTalentPool] = useState(false)

  const handleCloseTalentPool = () => setShowTalentPool(false)
  const handleShowTalentPool = () => setShowTalentPool(true)

  const callbackModalTalentPool = () => {
    setShowTalentPool(false)
  }

  let vacType = vacancy => {
    let contractTypeDisplay = ""

    switch (vacancy.cfCareersOpenRoles.openContractType) {
      case "both":
        contractTypeDisplay = "Permanent or Contract"
        break
      case "permanent":
        contractTypeDisplay = "Permanent Position"
        break
      case "contract":
        contractTypeDisplay = "Contract"
        break
      default:
        contractTypeDisplay = "Permanent or Contract"
    }

    return contractTypeDisplay
  }

  let salaryRange = vacancy => {
    let openSalary = "Competitive"

    if (
      vacancy.cfCareersOpenRoles.openSalary !== "" &&
      vacancy.cfCareersOpenRoles.openSalary !== null &&
      vacancy.cfCareersOpenRoles.openSalary !== undefined
    ) {
      openSalary = vacancy.cfCareersOpenRoles.openSalary
    }

    return openSalary
  }

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  // hero image 1
  let slug_1 = globals.singleVacHeroImage1.mediaDetails.file
  let altText_1 = globals.singleVacHeroImage1.altText

  let theImage_1 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_1}`)

  theImage_1.resize(fill().width(350).height(580))
  theImage_1.format("auto")

  // hero image 2
  let slug_2 = globals.singleVacHeroImage2.mediaDetails.file
  let altText_2 = globals.singleVacHeroImage2.altText

  let theImage_2 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_2}`)

  theImage_2.resize(fill().width(350).height(580))
  theImage_2.format("auto")

  // hero image 3
  let slug_3 = globals.singleVacHeroImage3.mediaDetails.file
  let altText_3 = globals.singleVacHeroImage3.altText

  let theImage_3 = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug_3}`)

  theImage_3.resize(fill().width(350).height(580))
  theImage_3.format("auto")

  let elLocation

  if (vacancy.cfCareersOpenRoles.openLocation == "hybridall") {
    elLocation = "Hybrid - from any of our UK offices"
  } 

  else if (vacancy.cfCareersOpenRoles.openLocation == "hybridscotland") {
    elLocation = "Hybrid - from any of our Scottish Offices"
  }
  
  else {
    elLocation =
      vacancy.cfCareersOpenRoles.openLocation.charAt(0).toUpperCase() +
      vacancy.cfCareersOpenRoles.openLocation.slice(1)
  }

  const isSafariV16 = isSafariLess16()

  return (
    <>
      <div className="darkgrey_bg mb-100 pt-6 pb-100">
        <div className="container pt-5">
          <div className="row align-items-center">
            <div
              id="hide-row-when-nav-open"
              className="col-12 col-lg-6 color-white animated-headline"
            >
              <div className="col-12">
                <div className="h5 header-fade-in bottom-0 pb-5">
                  <Link
                    to="/careers/open-roles/"
                    className={`${style.back_link} ani_grey_back_arrow`}
                    title="View All Open Roles"
                  >
                    Back to Open Roles
                  </Link>
                </div>
                <div className="animated-headline-wrap mb-5">
                  <h1 className="new_style lower">
                    <span
                      className={`new_style_addit ${
                        isSafariV16 ? "safari_16" : ""
                      }`}
                    >
                      {vacancy.title}
                    </span>
                  </h1>
                </div>
                <ul className="list-unstyled mb-4">
                  <li className="mb-3 d-flex">Location: {elLocation}</li>
                  <li className="mb-3 d-flex">Term: {vacType(vacancy)}</li>
                  <li className="mb-3 d-flex">
                    Salary: {salaryRange(vacancy)}
                  </li>
                </ul>
                <span
                  onClick={handleShowTalentPool}
                  role="link"
                  tabIndex={0}
                  className="ani_grey_arrow mt-4 d-inline-block"
                  title="Apply Now"
                >
                  Apply Now
                </span>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex align-items-center overflow-hidden mt-5 mt-lg-0">
              <AdvancedImage
                cldImg={theImage_1}
                alt={altText_1}
                className="tripple-images header-fade-in"
              />
              <AdvancedImage
                cldImg={theImage_2}
                alt={altText_2}
                className="tripple-images header-fade-in"
              />
              <AdvancedImage
                cldImg={theImage_3}
                alt={altText_3}
                className="tripple-images header-fade-in"
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showTalentPool}
        onHide={handleCloseTalentPool}
        fullscreen={true}
        scrollable={true}
      >
        <TalentPoolModal
          close={callbackModalTalentPool}
          recruiteeid={vacancy.cfCareersOpenRoles.openVacancyId}
          righttowork={vacancy.cfCareersOpenRoles.openHideRightToWorkInfo}
          position={vacancy.title}
          location={vacancy.cfCareersOpenRoles.openLocation}
          contract={vacType(vacancy)}
          showPreview={true}
          previewImg={slug_2}
          closemsg="Back to vacancy page"
        />
      </Modal>
    </>
  )
}

export default OpenRoleHero

import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { Modal } from "react-bootstrap"
import CareersBenefits from "../components/careers/careers-benefits"
import OpenRoleHero from "../components/careers/open-role-hero"
import CareerFaqs from "../components/careers/career-faqs"
import TalentPoolModal from "../components/careers/talent-pool-modal"
import { seoHead } from "../components/utils.js"

// Query
export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      vacancy(id: $id) {
        id
        title
        content
        uri
        cfCareersOpenRoles {
          openContractType
          openHideBenefitsSection
          openHideFaqsSectionCopy
          openHideRightToWorkInfo
          openIntroText
          openLocation
          openSalary
          openVacancyId
          roleFullDescription
          openDesireable {
            openDesireableBullet
          }
          openRequirements {
            openRequirementBullet
          }
          roleFullApplicationProcess
        }
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }

      acfOptionsCareers {
        cfCareersSingleVac {
          singleVacHeroImage1 {
            altText
            mediaDetails {
              file
            }
          }
          singleVacHeroImage2 {
            altText
            mediaDetails {
              file
            }
          }
          singleVacHeroImage3 {
            altText
            mediaDetails {
              file
            }
          }
          singleVacEqualTitle
          singleVacEqualMainContentParagraph
        }

        cfCareersFaqs {
          faqsIntroParagraph
          faqsSectionTitle
          faqsFaqs {
            faqsFaqAnswer
            faqsFaqQuestion
          }
        }

        cfBenefits {
          bensIntroParagraph
          bensTitle
          bensLhsBenefits {
            bensLhsBenefitName
            bensLhsIcon {
              altText
              mediaDetails {
                file
              }
            }
            bensLhsBenefitDetail
          }
          bensRhsBenefits {
            bensRhsBenefitName
            bensRhsIcon {
              altText
              mediaDetails {
                file
              }
            }
            bensRhsBenefitDetail
          }
        }
      }
    }
  }
`

// Build page
const PageTemplateCareersSingleRole = ({ location, data }) => {
  const vacancy = data.wpgraphql.vacancy
  const benefits = data.wpgraphql.acfOptionsCareers.cfBenefits
  const faqs = data.wpgraphql.acfOptionsCareers.cfCareersFaqs
  const singleInfo = data.wpgraphql.acfOptionsCareers.cfCareersSingleVac

  const previewImg = singleInfo.singleVacHeroImage2.mediaDetails.file

  const { state = {} } = location
  const { modal } = state || false

  const [showTalentPool, setShowTalentPool] = useState(modal)

  const handleCloseTalentPool = () => setShowTalentPool(false)
  const handleShowTalentPool = () => setShowTalentPool(true)
  const callbackModalTalentPool = () => {
    setShowTalentPool(false)
  }

  if (vacancy.seo.opengraphImage === null) {
    vacancy.seo.opengraphImage = {
      mimeType: "image/svg",
      mediaDetails: {
        file: "Blair-ResearchAdobeStock_414389634-1_26652dbceb.jpg",
      },
    }
  }

  let vacType = vacancy => {
    let contractTypeDisplay = ""

    switch (vacancy.cfCareersOpenRoles.openContractType) {
      case "both":
        contractTypeDisplay = "Permanent or Contract"
        break
      case "permanent":
        contractTypeDisplay = "Permanent Position"
        break
      case "contract":
        contractTypeDisplay = "Contract"
        break
      default:
        contractTypeDisplay = "Permanent or Contract"
    }

    return contractTypeDisplay
  }

  let theDescription = () => {
    let updatedCopy = vacancy.cfCareersOpenRoles.roleFullDescription.replace(
      /https:\/\/content.waracle.com/g,
      ""
    )
    // remove <span></span> generated in google docs and pasted into cms
    updatedCopy = updatedCopy.replace(/(<sp([^>]+)>)/gi, "")
    updatedCopy = updatedCopy.replace(/(<\/span>)/gi, "")
    return (
      <div
        dangerouslySetInnerHTML={{ __html: updatedCopy }}
        className="bullet_list"
      />
    )
  }

  return (
    <>
      <Layout>
        <OpenRoleHero pagedata={vacancy} globals={singleInfo} />

        <div className="container mb-100">
          <div className="row">
            <div className="col-12 col-lg-4 mb-3 mb-lg-0">
              <h3 className="lower">Job Description</h3>
            </div>
            <div className="col-12 col-lg-8">{theDescription()}</div>
          </div>
        </div>

        {vacancy.cfCareersOpenRoles.openRequirements !== null && (
          <div className="container mb-100">
            <div className="row">
              <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                <h3 className="lower">Requirements</h3>
              </div>
              <div className="col-12 col-lg-8">
                <ul className="bullet_list">
                  {vacancy.cfCareersOpenRoles.openRequirements.map(
                    (requirement, index) => (
                      <li key={index}>{requirement.openRequirementBullet}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}

        {vacancy.cfCareersOpenRoles.openDesireable !== null && (
          <div className="container mb-100">
            <div className="row">
              <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                <h3 className="lower">Desirable Experience</h3>
              </div>
              <div className="col-12 col-lg-8">
                <ul className="bullet_list">
                  {vacancy.cfCareersOpenRoles.openDesireable.map(
                    (desireable, index) => (
                      <li key={index}>{desireable.openDesireableBullet}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}

        {vacancy.cfCareersOpenRoles.roleFullApplicationProcess !== null && (
          <div className="container mb-100">
            <div className="row">
              <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                <h3 className="lower">Recruitment process</h3>
              </div>
              <div className="col-12 col-lg-8">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      vacancy.cfCareersOpenRoles.roleFullApplicationProcess,
                  }}
                  className="bullet_list"
                />
              </div>
            </div>
          </div>
        )}

        {vacancy.cfCareersOpenRoles.openHideBenefitsSection !== "true" && (
          <CareersBenefits pagedata={benefits} />
        )}

        {vacancy.cfCareersOpenRoles.openHideFaqsSectionCopy !== "true" && (
          <CareerFaqs pagedata={faqs} />
        )}

        <div className="container mb-6">
          <div className="row">
            <div className="col-12 col-lg-4 mb-3 mb-lg-0">
              <h3 className="lower">{singleInfo.singleVacEqualTitle}</h3>
            </div>
            <div className="col-12 col-lg-8">
              <p
                dangerouslySetInnerHTML={{
                  __html: singleInfo.singleVacEqualMainContentParagraph,
                }}
              />
              <p>
                We are not accepting applications from recruitment agencies.
              </p>
            </div>
          </div>
        </div>

        <div className="container mb-6">
          <div className="light_bg d-flex flex-wrap flex-column-reverse flex-sm-row align-items-center">
            <div className="col-12 col-sm-3 col-lg-2 ps-3 text-end text-sm-start">
              <img
                className="w-100 call_oo_action_img"
                src="https://res.cloudinary.com/dvmdv4ttu/image/upload/img/tom-williams-2_pogkv7.png"
                alt=""
              />
            </div>
            <div className="col-12 col-sm-9 col-lg-10 p-3 d-block d-lg-flex align-items-center">
              <div className="col-12 col-lg-10">
                <h3 className="mb-0 color-light-grey">Feel like a good fit?</h3>
                <h3 className="mb-3 mb-lg-0">Apply online now</h3>
              </div>
              <div className="col-12 col-lg-2">
                <span
                  onClick={handleShowTalentPool}
                  tabIndex={0}
                  role="link"
                  className="ani_grey_arrow white-circle"
                >
                  Apply Now
                </span>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showTalentPool}
          onHide={handleCloseTalentPool}
          fullscreen={true}
          scrollable={true}
        >
          <TalentPoolModal
            close={callbackModalTalentPool}
            recruiteeid={vacancy.cfCareersOpenRoles.openVacancyId}
            righttowork={vacancy.cfCareersOpenRoles.openHideRightToWorkInfo}
            position={vacancy.title}
            location={vacancy.cfCareersOpenRoles.openLocation}
            contract={vacType(vacancy)}
            showPreview={true}
            previewImg={previewImg}
            closemsg="Back to vacancy page"
          />
        </Modal>
      </Layout>
    </>
  )
}

export default PageTemplateCareersSingleRole

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.vacancy
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
